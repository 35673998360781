// import academicSetupModule from "./ar/academicSetup.mod.json";
// import classRoomModule from "./ar/classRoom.mod.json";
// import dashboardModule from "./ar/dashboard.mod.json";
// import DataTableComponent from "./ar/DataTable.component.json";
// import digitalLibraryModule from "./ar/digitalLibrary.mod.json";
// import financeModule from "./ar/finance.mod.json";
// import footerComponent from "./ar/footer.component.json";
// import forgetPasswordView from "./ar/forgetPassword.view.json";
// import hrModule from "./ar/hr.mod.json";
// import inboxModule from "./ar/inbox.mod.json";
// import itemSetupModule from "./ar/itemSetup.mod.json";
// import loginView from "./ar/login.view.json";
// import navbarComponent from "./ar/navbar.component.json";
// import notificationsModule from "./ar/notifications.mod.json";
// import reportsModule from "./ar/reports.mod.json";
// import schoolSetupModule from "./ar/schoolSetup.mod.json";
// import studentsModule from "./ar/students.mod.json";
// import teachersModule from "./ar/teachers.mod.json";
// import usersModule from "./ar/users.mod.json";
// import wareHouseModule from "./ar/wareHouse.mod.json";
// import EducationalHistory from "./ar/EducationalHistory.mod.json";

// import sideBar from "./ar/sideBar.component.json";
// import modals from "./ar/modals.json";
import ar from "./ar.json";

const Arabic = {
  // ...academicSetupModule,
  // ...classRoomModule,
  // ...dashboardModule,
  // ...DataTableComponent,
  // ...digitalLibraryModule,
  // ...financeModule,
  // ...footerComponent,
  // ...forgetPasswordView,
  // ...hrModule,
  // ...inboxModule,
  // ...itemSetupModule,
  // ...loginView,
  // ...navbarComponent,
  // ...notificationsModule,
  // ...reportsModule,
  // ...schoolSetupModule,
  // ...studentsModule,
  // ...teachersModule,
  // ...usersModule,
  // ...wareHouseModule,
  // ...sideBar,
  // ...modals,
  // ...EducationalHistory,
  ...ar
};

export default Arabic;
