export const BreadCrumbMixin = {
  data() {
    return {
      breadcrumbList: []
    };
  },
  $route: {
    handler() {
      if (
        this.$route.meta &&
        this.$route.meta.breadcrumb &&
        this.$route.meta.breadcrumb.length > 0
      ) {
        this.updateBreadCrumb();
      }
    }
  },
  methods: {
    updateBreadCrumb() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (
        this.$route.meta.view == "StudentsExamResults" ||
        this.$route.meta.view == "classexamreport" ||
        this.$route.meta.view == "examCorrection" ||
        this.$route.meta.view == "viewStudentJoined" ||
        this.$route.meta.view == "viewOnlineExam"
      ) {
        this.breadcrumbList.forEach(item => {
          if (item.text == "Exams") {
            item.href =
              "/manageclass/" +
              this.$route.params.academicYear +
              "/" +
              this.$route.params.classId +
              "?tab=3&page=1";
          }
          if (item.text == "Students Exam Results") {
            item.href =
              "/studentsexamresults/" +
              this.$route.params.academicYear +
              "/" +
              this.$route.params.classId +
              "/" +
              this.$route.params.examId;
          }
        });
      } else if (this.$route.meta.view == "applicantExamResults") {
        this.breadcrumbList.forEach(item => {
          if (item.text == "Grade") {
            item.href =
              "/gradeyears/" +
              this.$route.params.grade_year_id +
              "?tab=3&page=1";
          }
        });
      }
    }
  },
  mounted() {
    if (
      this.$route &&
      this.$route.meta &&
      this.$route.meta.breadcrumb &&
      this.$route.meta.breadcrumb.length > 0
    )
      this.updateBreadCrumb();
  }
};
