const ACL = {
  checkPermission(permission) {
    // var expiry = localStorage.expiry;
    // if (new Date().getTime() > expiry) {
    // session expired => logout
    // localStorage.clear();
    // window.location = "/login";
    // } else {
    var permissionsObject = JSON.parse(localStorage.permissionsObject);
    if (permissionsObject[permission] === undefined) return false;
    else if (permissionsObject[permission] === false) return false;
    else return true;
    // }
  },

  checkMenu(menu) {
    // var expiry = localStorage.expiry;
    // if (new Date().getTime() > expiry) {
    // session expired => logout
    // localStorage.clear();
    // window.location = "/login";
    // } else {
    var menus = JSON.parse(localStorage.menus);
    if (menus[menu] === undefined) return false;
    else if (menus[menu] === false) return false;
    else return true;
    // }
  },

  isLoggedIn() {
    // not logged in
    return localStorage.login && localStorage.login == "true";
  },

  Role(role) {
    if (typeof role == "object") {
      return role.includes(localStorage.selectedRole);
    }
    return localStorage.selectedRole == role ? true : false;
  },

  canAccessRoute(menuOrPermission) {
    // if (menuOrPermission) return true;
    // var expiry = localStorage.expiry;
    // if (new Date().getTime() > expiry) {
    // session expired => logout
    // localStorage.clear();
    // window.location = "/login";
    // } else {
    var permissionsObject = JSON.parse(localStorage.permissionsObject);
    var menus = JSON.parse(localStorage.menus);

    if (
      menus[menuOrPermission] === undefined &&
      permissionsObject[menuOrPermission] === undefined
    )
      return false;
    // doesn't have this menu or permission
    if (!menus[menuOrPermission] && !permissionsObject[menuOrPermission])
      return false;
    return true;
    // }
  },

  findHisDashboard() {
    // if (!localStorage.selectedRole) {
    //   return "/";
    // } else {
    //   // if (
    //   //   (localStorage.selectedRole == "Super-Admin" ||
    //   //     localStorage.selectedRole == "Coordinator") &&
    //   //   this.canAccessRoute("Dashboard")
    //   // )
    //   //   return "dashboard";
    //   // else if (
    //   //   localStorage.selectedRole == "Parent" &&
    //   //   this.canAccessRoute("Dashboard")
    //   // )
    //   //   return "dashboard";
    //   // else if (
    //   //   localStorage.selectedRole == "Teacher" &&
    //   //   this.canAccessRoute("Dashboard")
    //   // )
    //   //   return "dashboard";
    //   // else if (
    //   //   localStorage.selectedRole == "Student" &&
    //   //   this.canAccessRoute("Dashboard")
    //   // )
    //   //   return "dashboard";
    //   // if (
    //   //   localStorage.selectedRole != "Accountant" &&
    //   //   this.canAccessRoute("Dashboard")
    //   // ) {
    //   //   console.log(localStorage.selectedRole);
    //   //   return "dashboard";
    //   // } else {
    //   return "/";
    //   // }
    // }
    return "/";
  }
};

export default ACL;
