// import academicSetupModule from "./en/academicSetup.mod.json";
// import classRoomModule from "./en/classRoom.mod.json";
// import dashboardModule from "./en/dashboard.mod.json";
// import DataTableComponent from "./en/DataTable.component.json";
// import digitalLibraryModule from "./en/digitalLibrary.mod.json";
// import financeModule from "./en/finance.mod.json";
// import footerComponent from "./en/footer.component.json";
// import forgetPasswordView from "./en/forgetPassword.view.json";
// import hrModule from "./en/hr.mod.json";
// import inboxModule from "./en/inbox.mod.json";
// import itemSetupModule from "./en/itemSetup.mod.json";
// import loginView from "./en/login.view.json";
// import navbarComponent from "./en/navbar.component.json";
// import notificationsModule from "./en/notifications.mod.json";
// import reportsModule from "./en/reports.mod.json";
// import schoolSetupModule from "./en/schoolSetup.mod.json";
// import studentsModule from "./en/students.mod.json";
// import teachersModule from "./en/teachers.mod.json";
// import usersModule from "./en/users.mod.json";
// import wareHouseModule from "./en/wareHouse.mod.json";
// import EducationalHistory from "./en/EducationalHistory.mod.json";
// import sideBar from "./en/sidebar.component.json";
// import modals from "./en/modals.json";
import en from "./en.json";

const English = {
  // ...academicSetupModule,
  // ...classRoomModule,
  // ...dashboardModule,
  // ...DataTableComponent,
  // ...digitalLibraryModule,
  // ...financeModule,
  // ...footerComponent,
  // ...forgetPasswordView,
  // ...hrModule,
  // ...inboxModule,
  // ...itemSetupModule,
  // ...loginView,
  // ...navbarComponent,
  // ...notificationsModule,
  // ...reportsModule,
  // ...schoolSetupModule,
  // ...studentsModule,
  // ...teachersModule,
  // ...usersModule,
  // ...wareHouseModule,
  // ...sideBar,
  // ...modals,
  // ...EducationalHistory,
  ...en
};

export default English;
