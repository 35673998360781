import Vue from "vue";
import Vuetify from "vuetify";
import ar from "vuetify/lib/locale/ar";
import en from "vuetify/lib/locale/en";
Vue.use(Vuetify);
export default new Vuetify({
  rtl:
    localStorage.locale == "ar" || window.location.search.includes("?lang=ar"),
  lang: {
    locales: { ar, en },
    current:
      localStorage.locale == "ar" || window.location.search.includes("?lang=ar")
        ? "ar"
        : "en",
  },
});
