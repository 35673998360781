<template>
  <v-snackbar
    v-model="snack"
    timeout="7000"
    color="#7297ff"
    :bottom="true"
    :right="true"
    class="notitfication"
  >
    <span class="white--text">{{ text }}</span>
  </v-snackbar>
</template>

<script>
export default {
  name: "notificationpopup",
  data() {
    return {
      text: "",
      snack: false
    };
  },
  methods: {
    sendNotification(text) {
      this.snack = true;
      this.text = text;
    }
  }
};
</script>

<style></style>
