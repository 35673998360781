import jQuery from "jquery";
import $ from "jquery";
$(document).ready(function() {
  "use strict";

  /*-------------------------------------
      Sidebar Toggle Menu
    -------------------------------------*/
  $(".sidebar-toggle-view").on("click", ".sidebar-nav-item .nav-link", function(
    e
  ) {
    if (
      !$(this)
        .parents("#wrapper")
        .hasClass("sidebar-collapsed")
    ) {
      var animationSpeed = 300,
        subMenuSelector = ".sub-group-menu",
        $this = $(this),
        checkElement = $this.next();
      if (checkElement.is(subMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function() {
          checkElement.removeClass("menu-open");
        });
        checkElement.parent(".sidebar-nav-item").removeClass("active");
      } else if (
        checkElement.is(subMenuSelector) &&
        !checkElement.is(":visible")
      ) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("menu-open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function() {
          checkElement.addClass("menu-open");
          parent.find(".sidebar-nav-item.active").removeClass("active");
          parent_li.addClass("active");
        });
      }
      if (checkElement.is(subMenuSelector)) {
        e.preventDefault();
      }
    } else {
      if ($(this).attr("href") === "#") {
        e.preventDefault();
      }
    }
  });

  /*-------------------------------------
      Sidebar Menu Control
    -------------------------------------*/
  $(".sidebar-toggle").on("click", function() {
    window.setTimeout(function() {
      $("#wrapper").toggleClass("sidebar-collapsed");
    }, 500);
  });

  /*-------------------------------------
      Sidebar Menu Control Mobile
    -------------------------------------*/
  $(".sidebar-toggle-mobile").on("click", function() {
    $("#wrapper").toggleClass("sidebar-collapsed-mobile");
    if ($("#wrapper").hasClass("sidebar-collapsed")) {
      $("#wrapper").removeClass("sidebar-collapsed");
    }
  });

  /*-------------------------------------
      jquery Scollup activation code
   -------------------------------------*/
  $.scrollUp({
    scrollText: '<i class="fa fa-angle-up"></i>',
    easingType: "linear",
    scrollSpeed: 900,
    animation: "fade"
  });

  /*-------------------------------------
      jquery Scollup activation code
    -------------------------------------*/
  $("#preloader").fadeOut("slow", function() {
    $(this).remove();
  });

  $(function() {
    /*-------------------------------------
          Data Table init
      -------------------------------------*/
    if ($.fn.DataTable !== undefined) {
      $(".data-table").DataTable({
        paging: true,
        searching: false,
        info: false,
        lengthChange: false,
        lengthMenu: [20, 50, 75, 100],
        columnDefs: [
          {
            targets: [0, -1], // column or columns numbers
            orderable: false // set orderable for selected columns
          }
        ]
      });
    }

    /*-------------------------------------
          All Checkbox Checked
      -------------------------------------*/
    // $(".checkAll").on("click", function () {
    //   $(this).parents('.table').find('input:checkbox').prop('checked', this.checked);
    // });

    /*-------------------------------------
          Tooltip init
      -------------------------------------*/
    // $('[data-toggle="tooltip"]').tooltip();

    /*-------------------------------------
          Select 2 Init
      -------------------------------------*/
    if ($.fn.select2 !== undefined) {
      $(".select2").select2({
        width: "100%"
      });
    }

    /*-------------------------------------
          Date Picker
      -------------------------------------*/
    // if ($.fn.datepicker !== undefined) {
    //   $('.air-datepicker').datepicker({
    //     language: {
    //       days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    //       daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    //       daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    //       months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    //       monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //       today: 'Today',
    //       clear: 'Clear',
    //       dateFormat: 'dd/mm/yyyy',
    //       firstDay: 0
    //     }
    //   });
    // }

    /*-------------------------------------
          Counter
      -------------------------------------*/
    // var counterContainer = $(".counter");
    // if (counterContainer.length) {
    //   counterContainer.counterUp({
    //     delay: 50,
    //     time: 1000
    //   });
    // }

    /*-------------------------------------
          Calender initiate 
      -------------------------------------*/
    // if ($.fn.fullCalendar !== undefined) {
    //   $('#fc-calender').fullCalendar({
    //     header: {
    //       center: 'basicDay,basicWeek,month',
    //       left: 'title',
    //       right: 'prev,next',
    //     },
    //     fixedWeekCount: false,
    //     navLinks: true, // can click day/week names to navigate views
    //     editable: true,
    //     eventLimit: true, // allow "more" link when too many events
    //     aspectRatio: 1.8,
    //     events: [{
    //       title: 'All Day Event',
    //       start: '2019-04-01'
    //     },

    //     {
    //       title: 'Meeting',
    //       start: '2019-04-12T14:30:00'
    //     },
    //     {
    //       title: 'Happy Hour',
    //       start: '2019-04-15T17:30:00'
    //     },
    //     {
    //       title: 'Birthday Party',
    //       start: '2019-04-20T07:00:00'
    //     }
    //     ]
    //   });
    // }
  });
});
